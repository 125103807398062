import {EHolotrakAppVersion} from "contracts/EHolotrakAppVersion";
import {HolotrakUserSerializer} from "contracts/holotrak/holotrakUser";
import {useContext, useMemo} from "react";
import {selectCurrentUser} from "redux/slices/auth";
import {useTypedSelector} from "redux/store";
import {OrganisationContext} from "utils/organisationContext";

export function useAppVersion(targetVersion: EHolotrakAppVersion) {
    const organisation = useContext(OrganisationContext);
    const userFromState = useTypedSelector(selectCurrentUser);

    return useMemo(() => {
        if (!userFromState || !organisation) {
            return false;
        } else {
            const user = HolotrakUserSerializer.parse(userFromState);
            switch (targetVersion) {
                case EHolotrakAppVersion.V3:
                    return user.isVersionOverridePresent ? user.isV3Allowed : organisation.isV3Allowed;
                case EHolotrakAppVersion.V2:
                    const userVersion = user.userTags?.appVersion;
                    const orgVersion = organisation.groupTags?.appVersion;

                    return (userVersion ?? orgVersion) < 3;
                default:
                    return false;

            }
        }
    }, [userFromState, organisation, targetVersion]);
}
