import {EDeviceCommandTypes} from "contracts/EDeviceCommandTypes";
import {ESensorType} from "contracts/EDeviceStatus";
import {IReportableItem} from "contracts/IReportableItem";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {LosantPagination} from "./losantPagination";
import dayjs from "dayjs";
import {IHasTags} from "../IHasTags";



@jsonObject
export class ReportFilter {
    @jsonMember(String)
    endTime?: string;
    @jsonMember(String)
    startTime?: string;
    @jsonArrayMember(Number)
    controlDays?: number[];
    @jsonMember(String)
    reportTitle?: string;
    @jsonMember(String)
    endDateRange?: string;
    @jsonMember(String)
    startDateRange?: string;

    @jsonMember(String)
    reportId?: string;

    @jsonMember(String)
    organisationId?: string;


    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    endDate?: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    startDate?: dayjs.Dayjs;

}

@jsonObject
export class Report implements IReportableItem, IHasTags {
    @jsonMember(String)
    id: string;
    @jsonMember(String)
    title: string;
    @jsonMember(Number, {
        deserializer: (value: any) => {
            return value && parseInt(value);
        }
    })
    reportIndex: number;
    @jsonMember(String)
    organisation?: string;
    @jsonMember(ReportFilter)
    filters?: ReportFilter;

    @jsonArrayMember(String)
    devices?: string[];

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

    get additionalInfo(): string {
        return null;
    }

    get name(): string {
        return this.title;
    }

    get uiHeight(): number {
        return 130;
    }

    getTagValue(name: string): string | null {
        return null;
    }

    getWeekDaysTitle() {
        let title = " for Weekdays including ";
        const {controlDays} = this.filters;
        if (
            controlDays &&
            controlDays.length
        ) {
            title += `${(() => {
                let str = "";
                if (controlDays.includes(0)) {
                    str += " Sunday,";
                }
                if (controlDays.includes(1)) {
                    str += " Monday,";
                }
                if (controlDays.includes(2)) {
                    str += " Tuesday,";
                }
                if (controlDays.includes(3)) {
                    str += " Wednesday,";
                }
                if (controlDays.includes(4)) {
                    str += " Thursday,";
                }
                if (controlDays.includes(5)) {
                    str += " Friday,";
                }
                if (controlDays.includes(6)) {
                    str += " Saturday,";
                }
                return str;
            })()}`;
            title = title.replace(/,*$/, "");
        }
        return title;
    }

    getReportPeriodTitle() {
        return `from Period: ${this.filters.startDate.toISOString()} to ${this.filters.endDate.toISOString()}`;
    }

    get isMappable(): boolean {
        return false;
    }

    get sensorType(): ESensorType {
        return ESensorType.UNKNOWN;
    }

    getCommand(commandType: EDeviceCommandTypes, type?: string): string | void {
        return undefined;
    }
}

@jsonObject()
export class ReportListResponse extends LosantPagination {
    items: Report[];
}

export interface IReportCreation {
    controlDays: number[];
    devices: string;

    endDate: string;
    endDateRange: string;
    endTime: string;

    filters: string;

    reportIndex: number;
    reportTitle: string;


    startDate: string;
    startDateRange: string;
    startTime: string;
}

export const ReportSerializer = new TypedJSON(Report);
