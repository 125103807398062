const voltageValues = {
    'default': {
        minVoltage: 2.5,
        maxVoltage: 6.7,
    },
    'atrack': {
        minVoltage: 2.2,
        maxVoltage: 6.2,
    },
    'atrack-as-11': {
        minVoltage: 2.6,
        maxVoltage: 3.2,
    },
}

// Default voltage ranges and corresponding percentages
const voltageTableDefault = [
    {voltage: 12.7, percentage: 100},
    {voltage: 12.5, percentage: 90},
    {voltage: 12.42, percentage: 80},
    {voltage: 12.32, percentage: 70},
    {voltage: 12.2, percentage: 60},
    {voltage: 12.06, percentage: 50},
    {voltage: 11.9, percentage: 40},
    {voltage: 11.79, percentage: 30},
    {voltage: 11.58, percentage: 20},
    {voltage: 11.31, percentage: 10},
    {voltage: 10.5, percentage: 0},
];

// Voltage ranges for isdbarra
const voltageTableIsBarra = [
    {voltage: 3.6, percentage: 100},
    {voltage: 3.4, percentage: 90},
    {voltage: 3.3, percentage: 80},
    {voltage: 3.2, percentage: 70},
    {voltage: 3.1, percentage: 60},
    {voltage: 3.0, percentage: 50},
    {voltage: 2.9, percentage: 40},
    {voltage: 2.8, percentage: 30},
    {voltage: 2.6, percentage: 20},
    {voltage: 2.4, percentage: 10},
    {voltage: 2.2, percentage: 0},
];

export function calculateBatteryPercentage(vol, deviceModel) {
    const computeValues = voltageValues[deviceModel] ?? voltageValues['default'];
        const minVoltage = computeValues.minVoltage;
        const maxVoltage = computeValues.maxVoltage;
        const voltage = parseInt(vol) || 0
        let result: number;
        if (voltage <= minVoltage) {
            result = 0; // Voltage is below 2.6V, assume 0%
        } else if (voltage >= maxVoltage) {
            result = 100; // Voltage is above 4.1V, assume 100%
        } else {
            // Calculate the percentage between 2.6V and 4.1V
            result = ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
        }
        return `${result.toFixed(2)}%`;
}


export function computeVoltageForNonCSBDevice(battery_level: number) {
    try {
        if (battery_level) return ((battery_level / 100 + 5.52) / 1.23).toFixed(2);
        else return "-";
    } catch (e) {
        return "-";
    }
}


export function calculateBatteryInfo(voltageFromDevice: string, isBarraDevice: boolean) {
    const voltage = parseFloat(voltageFromDevice);
    try {
        // Choose the correct voltage table
        const voltageTable = isBarraDevice ? voltageTableIsBarra : voltageTableDefault;

        // Handle edge cases
        const maxVoltage = voltageTable[0].voltage;
        const minVoltage = voltageTable[voltageTable.length - 1].voltage;

        if (voltage >= maxVoltage) return 100;
        if (voltage <= minVoltage) return 0;

        // Find the two closest voltage entries
        for (let i = 0; i < voltageTable.length - 1; i++) {
            if (voltage <= voltageTable[i].voltage && voltage > voltageTable[i + 1].voltage) {
                const higherVoltage = voltageTable[i];
                const lowerVoltage = voltageTable[i + 1];

                // Interpolate between the two closest percentages
                const voltageRange = higherVoltage.voltage - lowerVoltage.voltage;
                const percentageRange = higherVoltage.percentage - lowerVoltage.percentage;
                const voltageAboveLower = voltage - lowerVoltage.voltage;

                const interpolatedPercentage =
                    lowerVoltage.percentage + (voltageAboveLower / voltageRange) * percentageRange;

                return Math.round(interpolatedPercentage);
            }
        }
    } catch (e) {
        return "-";
    }
}
