import type {MessageInstance} from "antd/es/message/interface";
import React from "react";

export interface HolotrakAppContextType {
    rootMessageApi: MessageInstance;
}

export const HolotrakAppContext = React.createContext<HolotrakAppContextType>({} as HolotrakAppContextType);

export const useHolotrakAppContext = () => React.useContext(HolotrakAppContext);
