import {EBatteryLevel} from "contracts/EBatteryLevel";
import {EDeviceSignal, EDeviceStatus} from "contracts/EDeviceStatus";
import {
    BooleanCompositeStateItem,
    CompositeStateItem,
    CompositeStateItemSerializer,
    CompositeStateItemSerializers,
    DateCompositeStateItem,
    FloatCompositeStateItem,
    IntCompositeStateItem
} from "contracts/holotrak/compositeStateItem";
import dayjs from "dayjs";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";

@jsonObject
export class CompositeState {
    /**
     * Common properties between CAN, Indoor and Outdoor Assets
     */
    @jsonMember(FloatCompositeStateItem)
    battery_level?: FloatCompositeStateItem;

    /**
     * CompositeStateItems for CAN and Outdoor Assets
     */
    @jsonMember(CompositeStateItem)
    location?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    address?: CompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    voltage_level?: FloatCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    fill_level?: FloatCompositeStateItem;

    @jsonMember(IntCompositeStateItem)
    rpm?: IntCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    fuel?: FloatCompositeStateItem;

    @jsonMember(CompositeStateItem)
    errors?: CompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    is_door_open?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    is_alarm_armed?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    is_alarm_triggered?: BooleanCompositeStateItem;

    /**
     * CompositeStateItems for Outdoor Assets
     */

    @jsonMember(BooleanCompositeStateItem)
    ignition?: BooleanCompositeStateItem;

    @jsonMember(String)
    lotName?: String;

    /**
     * CompositeStateItems for Indoor Assets
     */
    @jsonMember(CompositeStateItem)
    status?: CompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    position_x?: FloatCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    position_y?: FloatCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    position_z?: FloatCompositeStateItem;

    @jsonMember(CompositeStateItem)
    region_id?: CompositeStateItem;

    /**
     * CompositeStateItems for CAN Bus
     */

    @jsonMember(CompositeStateItem)
    engine_oil_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    brush_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    battery_terminal_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    air_cleaner_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    spark_plug_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    fuel_filter_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    aux_usage?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    weld_usage?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    idle_time?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ac_run_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ac_oil_maintenance_hours?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ac_min_psi_setting?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ac_max_psi_setting?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    hydraulics_max_psi?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    hydraulics_max_flow?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    hydraulics_feedback_flow?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    hydraulics_oil_temp?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    hydraulics_usage?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_stick_pu?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_tig_pu?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_gauge_pu?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_spool_gun_pu?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_feeder_pu?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    auto_start_stop_uc?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    pin_usage?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    battery_charge_12v?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    battery_charge_24v?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_stick_process_avgc?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_tig_process_avgc?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_gauge_process_avgc?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_spool_gun_avgc?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    machine_feeder_avgc?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    cb_apploader_pn?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    cb_application_pn?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    uib_application_pn?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    uib_apploader_pn?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    uib_applauncher_pn?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    cb_applauncher_pn?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    cb_application_sr?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    cb_applauncher_sr?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    cb_apploader_sr?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ui_application_sr?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ui_applauncher_sr?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    ui_apploader_sr?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    sys_product_class?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    sys_product_config?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    archreach_bind_attempts?: CompositeStateItem;


    @jsonMember(BooleanCompositeStateItem)
    tilt?: BooleanCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    altitude?: FloatCompositeStateItem;

    @jsonMember(CompositeStateItem)
    temperature?: FloatCompositeStateItem;


    @jsonMember(DateCompositeStateItem)
    last_reported_at?: DateCompositeStateItem;

    @jsonMember(DateCompositeStateItem)
    last_location_updated_at?: DateCompositeStateItem;

    @jsonMember(CompositeStateItem)
    hdop?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    speed?: IntCompositeStateItem;

    @jsonMember(IntCompositeStateItem)
    carrier?: CompositeStateItem;

    @jsonMember(IntCompositeStateItem)
    direction?: IntCompositeStateItem;

    @jsonMember(IntCompositeStateItem)
    eventCode?: IntCompositeStateItem;

    @jsonMember(CompositeStateItem)
    trip_time?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    vibration?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    comm_state?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    satellites?: CompositeStateItem;

    @jsonMember(CompositeStateItem)
    trip_distance?: CompositeStateItem;

    @jsonMember(IntCompositeStateItem)
    signal_strength?: IntCompositeStateItem;

    @jsonMember(CompositeStateItem)
    external_power_cut?: CompositeStateItem;

    @jsonMember(IntCompositeStateItem)
    humidity?: FloatCompositeStateItem;

    // noinspection SpellCheckingInspection - this is how it is spelled in the API
    @jsonMember(FloatCompositeStateItem, {name: "coolent_temperature"})
    coolant_temperature?: IntCompositeStateItem;

    @jsonMember(CompositeStateItem)
    coolant?: CompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    throttle?: FloatCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    absolute_load_value?: FloatCompositeStateItem;

    @jsonMember(FloatCompositeStateItem)
    engine_start_run_time?: FloatCompositeStateItem;

    @jsonMember(CompositeStateItem)
    distance_mil_on?: CompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_1?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_2?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_3?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_4?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_5?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_6?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    input_7?: BooleanCompositeStateItem;


    @jsonMember(BooleanCompositeStateItem)
    output_1?: BooleanCompositeStateItem;

    @jsonMember(BooleanCompositeStateItem)
    output_2?: BooleanCompositeStateItem;

    computeBatteryLevel(): EBatteryLevel {
        const battery = this.getItem('battery_level');
        if (battery?.hasValue()) {
            const batteryLevel = battery.getIntValue(0);
            if (batteryLevel >= 90) {
                return EBatteryLevel.FULL;
            }
            if (batteryLevel >= 20 && batteryLevel < 90) {
                return EBatteryLevel.LOW;
            }
            if (batteryLevel < 20) {
                return EBatteryLevel.WARNING;
            }
        }

        return EBatteryLevel.NOT_CONNECTED;
    }

    getLastUpdatedIndoorPosition(): CompositeStateItem {
        const positions = [
            this.getItem('position_x'),
            this.getItem('position_y'),
            this.getItem('position_z'),
        ].filter(position => position.hasValue()).sort((a, b) => {
            return (a.time && b.time) ? a.time.diff(b.time) : 0;
        });

        return positions[0] ?? new CompositeStateItem(null, dayjs());
    }

    computeSignalStrength(): EDeviceSignal {
        const signal = this.getItem('signal_strength');
        if (signal?.hasValue()) {
            const signalStrength = signal.getIntValue(0);
            if (signalStrength >= 75) {
                return EDeviceSignal.EXCELLENT;
            }
            if (signalStrength >= 55 && signalStrength < 75) {
                return EDeviceSignal.GOOD;
            }
            if (signalStrength >= 25 && signalStrength < 55) {
                return EDeviceSignal.POOR;
            }
            if (signalStrength < 25) {
                return EDeviceSignal.BAD;
            }
        }

        return EDeviceSignal.NONE;
    }

    computeAlarmStatus(): EDeviceStatus {
        const lastReportedAt = this.getItem('last_reported_at');
        const isAlarmArmed = this.getItem('is_alarm_armed');

        if (lastReportedAt.hasValue() && lastReportedAt.secondsElapsed()! <= CompositeStateItem.getFiveMinutesInSeconds()) {
            return EDeviceStatus.ONLINE;
        }

        if (isAlarmArmed.hasValue() && isAlarmArmed.getFiniteValue() === true) {
            return EDeviceStatus.OFFLINE;
        }

        if (lastReportedAt.hasValue() && lastReportedAt.secondsElapsed()! > CompositeStateItem.getFifteenDaysInSeconds()) {
            return EDeviceStatus.STOPPED_REPORTING;
        }

        return EDeviceStatus.NEVER_CONNECTED;
    }

    computeIndoorStatus(): EDeviceStatus {
        const fiveMinutesInSeconds = CompositeStateItem.getFiveMinutesInSeconds();
        const fifteenDaysInSeconds = CompositeStateItem.getFifteenDaysInSeconds();

        const position = this.getLastUpdatedIndoorPosition();

        const lastReportedAt = this.getItem('last_reported_at');

        if (!position.hasValue() && !lastReportedAt.hasValue()) {
            return EDeviceStatus.NEVER_CONNECTED;
        }

        if (position.hasValue() && lastReportedAt.hasValue()) {
            if (lastReportedAt.secondsElapsed()! < fifteenDaysInSeconds &&
                lastReportedAt.secondsElapsed()! > fiveMinutesInSeconds) {
                return EDeviceStatus.OFFLINE;
            }
        }

        if (lastReportedAt.hasValue()) {
            if (position?.hasValue() &&
                lastReportedAt.secondsElapsed()! > fifteenDaysInSeconds) {
                return EDeviceStatus.STOPPED_REPORTING;
            }
        }


        if (lastReportedAt.hasValue() && lastReportedAt.secondsElapsed() <= fiveMinutesInSeconds) {
            return EDeviceStatus.ONLINE;
        }

        return EDeviceStatus.NEVER_CONNECTED;
    }

    computeOutdoorStatus(): EDeviceStatus {
        const fiveMinutesInSeconds = CompositeStateItem.getFiveMinutesInSeconds();
        const fifteenDaysInSeconds = CompositeStateItem.getFifteenDaysInSeconds();

        const location = this.getItem('location');
        const speed = this.getItem('speed');
        const lastReportedAt = this.getItem('last_reported_at');
        const lastLocationUpdatedAt = this.getItem('last_location_updated_at');

        // FIXME: Location should not be part of the status computation
        if (!location.hasValue() && !lastReportedAt.hasValue()) {
            return EDeviceStatus.NEVER_CONNECTED;
        }

        if (location.hasValue() && lastReportedAt.hasValue()) {
            if (lastReportedAt.secondsElapsed()! < fifteenDaysInSeconds &&
                lastReportedAt.secondsElapsed()! > fiveMinutesInSeconds) {
                return EDeviceStatus.OFFLINE;
            }
        }

        // TODO: Double check this logic
        // MAYBE: for timebeing 30 days
        // FUTURE: Daily Reporting will be enabled, so we can reduce this to 1 day i.e. STOPPED REPORTING >= 24.5 hours
        if (lastReportedAt.hasValue()) {
            if (location?.hasValue() &&
                lastReportedAt.secondsElapsed()! > fifteenDaysInSeconds) {
                return EDeviceStatus.STOPPED_REPORTING;
            }
        }

        // FIXME: Ignition is On but speed is 0 for more than last 5 minutes.
        // FUTURE: The time difference could be configurable per device basis
        if (lastLocationUpdatedAt.hasValue()) {
            if (lastLocationUpdatedAt.secondsElapsed()! > fifteenDaysInSeconds) {
                return EDeviceStatus.IDLE;
            }
        }

        if (speed?.hasValue() || lastReportedAt.hasValue()) {
            if (speed?.getIntValue(0) > 0 ||
                lastReportedAt.secondsElapsed() <= fiveMinutesInSeconds) {
                return EDeviceStatus.ONLINE;
            }
        }

        return EDeviceStatus.NEVER_CONNECTED;
    }

    getItem(key: CompositeStateType): CompositeStateItem {
        const serializer = CompositeStateItemSerializers[key] || CompositeStateItemSerializer;
        return serializer.parse(this[key] || {value: null, time: null});
    }
}

export const CompositeStateParser = new TypedJSON(CompositeState);

export type CompositeStateType = keyof CompositeState;
