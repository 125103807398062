export enum EDeviceEventType {
    COMMAND = 'command',
    STATUS = 'status',
}

export function getDeviceEventTypeLabel(type: EDeviceEventType): string {
    switch (type) {
        case EDeviceEventType.COMMAND:
            return 'Command';
        case EDeviceEventType.STATUS:
        default:
            return 'Status';
    }
}

export function getDeviceEventType(type: string): EDeviceEventType {
    switch (type?.toLowerCase()) {
        case 'command':
            return EDeviceEventType.COMMAND;
        case 'status':
        default:
            return EDeviceEventType.STATUS;
    }
}
