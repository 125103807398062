import {Layout, message, theme} from "antd";
import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import {HolotrakAppContext} from "utils/HolotrakAppContext";
import {useGeolocationInfo} from "utils/useGeolocationInfo";
import {HolotrakSider} from "../../components/core/HolotrakSider";
import {ExperienceGroup, ExperienceGroupSerializer} from "../../contracts/holotrak/experienceGroup";
import {useGetDeviceSensorsQuery, useGetDevicesQuery} from "../../redux/services/express";
import {useLazyGetOrganisationQuery} from "../../redux/services/losant";
import {selectCurrentUser} from "../../redux/slices/auth";
import {useTypedSelector} from "../../redux/store";
import {OrganisationContext} from "../../utils/organisationContext";

const {Content} = Layout;


const HolotrakRoot: React.FC = () => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const [trigger, {
        isLoading: isLoadingOrganisation,
        isSuccess: isOrganisationLoaded
    }] = useLazyGetOrganisationQuery();
    const currentUser = useTypedSelector(selectCurrentUser);
    const [organisation, setOrganisation] = React.useState<ExperienceGroup | null>(null);


    const [messageApi, messageContext] = message.useMessage();

    useGetDevicesQuery();
    useGetDeviceSensorsQuery();
    useGeolocationInfo();


    useEffect(() => {
        if (currentUser?.id && !isLoadingOrganisation && !isOrganisationLoaded) {
            trigger().then((response) => {
                setOrganisation(ExperienceGroupSerializer.parse(response.data));
            });
        }
    }, [currentUser?.id, isLoadingOrganisation, isOrganisationLoaded, trigger]);


    useEffect(() => {
        if (organisation?.id) {
            //FUTURE: Find out where this orgId is being used
            localStorage.setItem('orgId', organisation.id)
        }
    }, [organisation]);

    return (
        <div className="App">
            <OrganisationContext.Provider value={organisation}>
                <HolotrakAppContext.Provider value={{
                    rootMessageApi: messageApi,
                }}>
                    <Layout hasSider>
                        <HolotrakSider/>

                        {messageContext}

                        <Layout className="site-layout">
                            <Content
                                style={{
                                    height: '100vh',
                                    overflowY: 'auto',
                                    background: colorBgContainer,
                                }}
                            >
                                <Outlet/>
                            </Content>
                        </Layout>
                    </Layout>
                </HolotrakAppContext.Provider>
            </OrganisationContext.Provider>
        </div>
    );
};

export default HolotrakRoot;
