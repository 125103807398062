import {Button, Col, Drawer, FloatButton, Row, Spin} from "antd";
import {IconArrowRightCircle} from "assets/icons/icon-arrow-right-circle";
import {BottomBar} from "components/bottom-bar/BottomBar";

import GeofenceDrawer from "components/geofence-management/drawer/GeofenceDrawer";
import {EHolotrakAppVersion} from "contracts/EHolotrakAppVersion";

import React, {useCallback, useEffect, useMemo} from "react";
import {Helmet} from "react-helmet";
import {Link, Route, Routes, useMatch} from "react-router-dom";
import {
    selectFilteredDevices,
    selectGroupMarkedDevices,
    selectMapBoundMovingDevices,
    useGetDeviceSensorsQuery,
    useGetDevicesQuery
} from "redux/services/express";
import {selectIsShowingGroups, selectLeftDrawerOpen, selectMapConfig, toggleLeftDrawer} from "redux/slices/app";

import {useAppDispatch, useTypedSelector} from "redux/store";

import './devices.scss';
import {useAppVersion} from "utils/useAppVersion";

const DeviceList = React.lazy(() => import("./list"));
const DeviceDetailsV2 = React.lazy(() => import("components/device-details/v2"));
const DeviceDetailsV3 = React.lazy(() => import("components/device-details/v3"));

const Trips = React.lazy(() => import("./trips"));
const DeviceEventsPage = React.lazy(() => import("pages/devices-ui/device-events-page"));

const MapPane = React.lazy(() => import("components/map-pane"));

export const Devices: React.FC = () => {
    const dispatch = useAppDispatch();
    // TODO: Details page itself should check what to render since the rest of pages are almost same.
    const isViewingDeviceDetails = useMatch("/devices/:id");

    const isOnAppV2 = useAppVersion(EHolotrakAppVersion.V2);

    // Using a query hook automatically fetches data and returns query values
    const {isLoading: isLoadingDevices, error: errorInLoadingDevices, refetch} = useGetDevicesQuery();
    const {
        isLoading: isLoadingDeviceSensors,
        error: errorInLoadingDeviceSensors,
        refetch: refetchDeviceSensors
    } = useGetDeviceSensorsQuery();

    const mapConfig = useTypedSelector(selectMapConfig);

    const DeviceDetails = useMemo(() => isOnAppV2 ? DeviceDetailsV2 : DeviceDetailsV3, [isOnAppV2]);

    const drawerWidth = useMemo<number>(() => {
        if (isViewingDeviceDetails) {
            return isOnAppV2 ? 378 : 653;
        } else {
            return 378;
        }
    }, [isViewingDeviceDetails, isOnAppV2]);

    const devices = useTypedSelector(selectFilteredDevices);
    const groupMarkedDevices = useTypedSelector(selectGroupMarkedDevices);
    const {still: stillDevices, moving: movingDevices} = useTypedSelector(selectMapBoundMovingDevices);

    const isLeftDrawerOpen = useTypedSelector(selectLeftDrawerOpen);
    const isShowingGroups = useTypedSelector(selectIsShowingGroups);

    const toggleDrawer = useCallback(() => {
        dispatch(toggleLeftDrawer());
    }, [dispatch]);

    const [bottomBarHeight, setBottomBarHeight] = React.useState(0);
    const bottomBarRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (bottomBarRef.current) {
            setBottomBarHeight(bottomBarRef.current.clientHeight);
        }
    }, [bottomBarRef]);

    return (
        <>
            <Helmet>
                <title>Devices</title>
                <meta name='description' content={'Holotrak Managed Devices'}/>
            </Helmet>

            <Row>
                <Col span={24}>

                    <FloatButton
                        className="left-drawer-toggle"
                        type='default'
                        shape="square"
                        onClick={toggleDrawer}
                        icon={<IconArrowRightCircle/>}
                    />

                    <Drawer
                        placement="left"
                        mask={false}
                        open={isLeftDrawerOpen}
                        closable={false}
                        getContainer={false}
                        motion={null}
                        bodyStyle={{padding: 0}}
                        width={drawerWidth}
                    >
                        <React.Suspense fallback={<Spin spinning={true}/>}>
                            <Routes>
                                <Route path='/' index
                                       element={
                                           <DeviceList
                                               error={errorInLoadingDevices || errorInLoadingDeviceSensors}
                                               isLoading={isLoadingDevices || isLoadingDeviceSensors}
                                               items={devices}
                                           />
                                       }/>
                                <Route path="/:id" element={<DeviceDetails/>}/>
                                <Route path="/:id/trips" element={<Trips/>}/>
                                <Route path="/:id/door-status" element={<DeviceEventsPage/>}/>
                                <Route path="/:id/ignition" element={<DeviceEventsPage filter="ignition"/>}/>
                            </Routes>
                        </React.Suspense>
                    </Drawer>
                    <React.Suspense fallback={<Spin spinning={true}/>}>
                        <MapPane
                            consumedHeight={bottomBarHeight}
                            stillItems={stillDevices}
                            movingItems={movingDevices}
                        />
                        <GeofenceDrawer/>
                    </React.Suspense>
                    <Row ref={bottomBarRef}>
                        <Col span={24}>
                            <BottomBar>
                                <Link to={'/report/devices'}>
                                    <Button size='small'>
                                        List
                                    </Button>
                                </Link>
                            </BottomBar>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
