export enum EGeofencingLogic {
    ON_BOARD = 'on_board',
    ON_PLATFORM = 'on_platform',
}

export function getDeviceGeofencingLogicLabel(type: EGeofencingLogic) {
    switch (type) {
        case EGeofencingLogic.ON_PLATFORM:
            return 'On Platform';
        case EGeofencingLogic.ON_BOARD:
        default:
            return 'On Board';
    }
}

export function getDeviceGeofencingLogic(type: string): EGeofencingLogic {
    switch (type?.toLowerCase()) {
        case 'on_platform':
            return EGeofencingLogic.ON_PLATFORM;
        case 'on_board':
        default:
            return EGeofencingLogic.ON_BOARD;
    }
}
