import {CompositeStateItem, FloatCompositeStateItem} from "contracts/holotrak/compositeStateItem";
import {jsonArrayMember, jsonObject, TypedJSON} from "typedjson";


@jsonObject()
export class DataGraphResponse {
    @jsonArrayMember(() => FloatCompositeStateItem)
    battery: FloatCompositeStateItem[];

    @jsonArrayMember(() => FloatCompositeStateItem)
    voltage_level: FloatCompositeStateItem[];

    @jsonArrayMember(() => FloatCompositeStateItem)
    fill_level: FloatCompositeStateItem[];

    @jsonArrayMember(() => FloatCompositeStateItem)
    temperature: FloatCompositeStateItem[];

    @jsonArrayMember(() => FloatCompositeStateItem)
    humidity: FloatCompositeStateItem[];

    @jsonArrayMember(() => CompositeStateItem)
    door: CompositeStateItem[];

    @jsonArrayMember(() => CompositeStateItem)
    ignition: CompositeStateItem[];
}

export const DataGraphResponseSerializer = new TypedJSON(DataGraphResponse);
