import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import authenticationMiddleware from "redux/authentication-middleware";
import {expressApi, SERVICE_REDUCER_PATH as EXPRESS_REDUCER_PATH} from "./services/express";
import {losantApi} from "./services/losant";

import appSliceReducer from "./slices/app";
import authSliceReducer from "./slices/auth";
import {LOSANT_API_REDUCER_KEY as LOSANT_REDUCER_PATH, TRIPS_REDUCER_KEY} from "../contracts/reduxResourceTags";
import { tripsApi } from "./services/losant/trips";
import tripsSliceReducer from "./slices/trip"

export const store = configureStore({
    reducer: {
        app: appSliceReducer,
        auth: authSliceReducer,
        tripsCore: tripsSliceReducer,
        [EXPRESS_REDUCER_PATH]: expressApi.reducer,
        [LOSANT_REDUCER_PATH]: losantApi.reducer,
        [TRIPS_REDUCER_KEY]: tripsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            expressApi.middleware,
            losantApi.middleware,
            tripsApi.middleware,
            authenticationMiddleware
        ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
