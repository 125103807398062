export enum EDeviceEventLevel {
    CRITICAL = 'critical',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

export function getDeviceEventLevelLabel(level: EDeviceEventLevel): string {
    switch (level) {
        case EDeviceEventLevel.CRITICAL:
            return 'Critical';
        case EDeviceEventLevel.ERROR:
            return 'Error';
        case EDeviceEventLevel.WARNING:
            return 'Warning';
        case EDeviceEventLevel.INFO:
        default:
            return 'Info';
    }
}

export function getDeviceEventLevel(level: string): EDeviceEventLevel {
    switch (level?.toLowerCase()) {
        case 'critical':
            return EDeviceEventLevel.CRITICAL;
        case 'error':
            return EDeviceEventLevel.ERROR;
        case 'warning':
            return EDeviceEventLevel.WARNING;
        case 'info':
        default:
            return EDeviceEventLevel.INFO;
    }
}
