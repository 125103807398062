import {SensorReportResponse} from "contracts/holotrak/sensorBase";
import {expressApi} from "./index";
import {IReportCreation, Report, ReportListResponse} from "../../../contracts/holotrak/report";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";
import {DeviceReportResponse} from "../../../contracts/holotrak/device";
import {TripSummaryReport} from "../../../contracts/holotrak/TripSummaryReport";
import {TripDetailReport} from "../../../contracts/holotrak/TripDetailReport";
import {TAG_DEVICE_SENSOR, TAG_DEVICE_SENSOR_REPORT, TAG_HISTORICAL_REPORT} from "../../../contracts/reduxResourceTags";
import {IndoorAssetReportResponse} from "../../../contracts/holotrak/IndoorAsset";


const reportingApi = expressApi.injectEndpoints({
    endpoints: (builder) => ({
        // Reports List
        getReportsList: builder.query<ReportListResponse, ILosantListQuery>({
            providesTags: (result, error, arg, meta) => {
                return [{type: TAG_HISTORICAL_REPORT, id: 'LIST'}, ...result.items.map(({id}) => ({type: TAG_HISTORICAL_REPORT, id}))];
            },
            query: (queryArgs) => {
                const {fieldKey, fieldValue} = queryArgs;
                const sortQuery = `sortField=createdAt&sortDirection=asc`;
                const fieldQuery = (fieldKey && fieldValue) ? `&filterField=${fieldKey}&filterValue=${fieldValue}` : '';
                const dateQuery = (queryArgs.startDateRange && queryArgs.endDateRange) ? `&startDateRange=${queryArgs.startDateRange}&endDateRange=${queryArgs.endDateRange}` : '';

                return {
                    url: `/reports?${sortQuery}${fieldQuery}${dateQuery}`,
                    method: 'GET',
                }
            }
        }),

        // Device Report
        getDeviceExpiryReport: builder.query<DeviceReportResponse, void>({
            query: () => '/downloadAllDevices'
        }),

        // Device Report
        getSensorExpiryReport: builder.query<SensorReportResponse, void>({
            providesTags: (result, error, arg, meta) => {
              return [
                  {type: TAG_DEVICE_SENSOR_REPORT, id: 'LIST'},
                  ...result.items.map(({id}) => ({type: TAG_DEVICE_SENSOR_REPORT, id}))
              ];
            },
            query: () => '/downloadAllSensors'
        }),

        // Indoor Asset Report
        getIndoorAssetExpiryReport: builder.query<IndoorAssetReportResponse, void>({
            query: () => '/downloadAllTrackers'
        }),

        // Device Summary Report
        getDeviceSummaryReport: builder.mutation<TripSummaryReport, Report>({
            invalidatesTags: (result, error, report) => {
                return [{type: TAG_HISTORICAL_REPORT, id: report.id}];
            },
            query: (report: Report) => {
                let body = {
                    filters: report.filters,
                    devices: report.devices,
                };

                return {
                    url: '/device-summary/v2',
                    method: 'POST',
                    body
                };
            }
        }),


        // Device Summary Report
        getTripHistoryReport: builder.mutation<TripDetailReport, Report>({
            invalidatesTags: (result, error, report) => {
                return [{type: TAG_HISTORICAL_REPORT, id: report.id}];
            },
            query: (report: Report) => {
                let body = {
                    filters: report.filters,
                    devices: report.devices,
                };

                return {
                    url: '/trips-history-report/v2',
                    method: 'POST',
                    body
                };
            }
        }),

        // Create Report
        createReport: builder.mutation<Report, IReportCreation>({
            invalidatesTags: (response) => {
                return [{type: TAG_HISTORICAL_REPORT, id: 'LIST'}, {type: TAG_HISTORICAL_REPORT, id: response.id}];
            },
            query: (report: IReportCreation) => ({
                url: `/reports`,
                method: 'POST',
                body: report
            })
        }),

        // Delete Report
        deleteReport: builder.mutation<void, string>({
            invalidatesTags: (result, error, reportId) => {
                return [{type: TAG_HISTORICAL_REPORT, id: 'LIST'}, {type: TAG_HISTORICAL_REPORT, id: reportId}];
            },
            query: (reportId: string) => ({
                url: `/reports/${reportId}`,
                method: 'DELETE',
            })
        }),

    })
});

export const {
    useGetReportsListQuery,
    useGetDeviceExpiryReportQuery,
    useGetSensorExpiryReportQuery,
    useGetIndoorAssetExpiryReportQuery,
    useGetDeviceSummaryReportMutation,
    useGetTripHistoryReportMutation,
    useCreateReportMutation,
    useDeleteReportMutation,
} = reportingApi;


export type TReportQuery = typeof useGetDeviceSummaryReportMutation | typeof useGetTripHistoryReportMutation;
