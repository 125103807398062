import {jsonMember, jsonObject, TypedJSON} from "typedjson";

@jsonObject()
export class EventData {
    @jsonMember(String)
    deviceId: string;
    @jsonMember(String)
    smsCode: string;
}

export const EventDataSerializer = new TypedJSON(EventData);
