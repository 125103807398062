import SensorReport from "pages/sensors/report";
import { HolotrakUser } from "./contracts/holotrak/holotrakUser";
import { useTypedSelector } from "./redux/store";
import { selectCurrentUser } from "./redux/slices/auth";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import React from "react";
import HolotrakRoot from "./pages/holotrak-root";

import { Login } from "pages/login";
import { Logout } from "pages/login/Logout";
import { Drivers } from "pages/drivers";
import { IndoorAssets } from "pages/indoor-assets";
import { IndoorPositions } from "pages/indoor-positions";
import { Settings } from "pages/settings";
import { Notifications } from "./pages/notifications";
import { Messages } from "./pages/messages";
import { DeviceReport } from "./pages/devices/report";
import { HistoricalReports } from "./pages/reports";
import { HelpPage } from "./pages/help";
import { AdminLogin } from "./pages/login/AdminLogin";
import PageNotFound from "./pages/errors/PageNotFound";
import { Dashboard } from "./pages/dashboard";
import { Devices } from "./pages/devices";
import { Sensors } from "./pages/sensors";
import { Vehicles } from "./pages/vehicles";
import IndoorAssetsReport from "./pages/indoor-assets/report";
import ForgetPassword from "./pages/login/forget-password";
import ResetPassword from "./pages/login/reset-password";
import VehicleReport from "./pages/vehicles/report";
import DriverReport from "./pages/drivers/report";
import Maintenance from "pages/maintenance";
import MaintenanceLog from "pages/maintenanceLog";
import MaintenanceCalender from "pages/maintenanceCal/MaintenanceCalender";

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
    const user = useTypedSelector(selectCurrentUser);
    return user ? element : <Navigate to={"/login"} />;
};

const GuestRoute = ({ element }: { element: JSX.Element }) => {
    const user = useTypedSelector(selectCurrentUser);
    return !user ? element : <Navigate to={"/"} />;
};

function getApplicationRoutes(user: HolotrakUser) {
    // TODO: Check if user is authenticated and based on access level, return the appropriate routes
    return [
        {
            path: "",
            element: <HolotrakRoot />,
            errorElement: <PageNotFound />,
            children: [
                {
                    index: true,
                    path: "/",
                    element: <ProtectedRoute element={<Dashboard />} />,
                },
                {
                    path: "/devices/*",
                    element: <ProtectedRoute element={<Devices />} />,
                },
                {
                    path: "/device-sensors/*",
                    element: <ProtectedRoute element={<Sensors />} />,
                },
                {
                    path: "/vehicles/*",
                    element: <ProtectedRoute element={<Vehicles />} />,
                },
                {
                    path: "/drivers/*",
                    element: <ProtectedRoute element={<Drivers />} />,
                },
                {
                    path: "/indoor-assets/*",
                    element: <ProtectedRoute element={<IndoorAssets />} />,
                },

                {
                    path: "/indoor-positions/*",
                    element: <ProtectedRoute element={<IndoorPositions />} />,
                },
                {
                    path: "/report/devices",
                    element: <ProtectedRoute element={<DeviceReport />} />,
                },
                {
                    path: "/report/device-sensors",
                    element: <ProtectedRoute element={<SensorReport />} />,
                },
                {
                    path: "/report/vehicles",
                    element: <ProtectedRoute element={<VehicleReport />} />,
                },
                {
                    path: "/report/drivers",
                    element: <ProtectedRoute element={<DriverReport />} />,
                },
                {
                    path: "/report/indoor-assets",
                    element: <ProtectedRoute element={<IndoorAssetsReport />} />,
                },
                {
                    path: "/report/historical",
                    element: <ProtectedRoute element={<HistoricalReports />} />,
                },
                {
                    path: "/help",
                    element: <ProtectedRoute element={<HelpPage />} />,
                },
                {
                    path: "/notifications",
                    element: <ProtectedRoute element={<Notifications />} />,
                },
                {
                    path: "/messages",
                    element: <ProtectedRoute element={<Messages />} />,
                },
                {
                    path: "/settings/*",
                    element: <ProtectedRoute element={<Settings />} />,
                },
                {
                    path: "/maintenance",
                    element: <ProtectedRoute element={<Maintenance />} />,
                },
                {
                    path: "/maintenance-log",
                    element: <ProtectedRoute element={<MaintenanceLog />} />,
                },

                {
                    path: "/maintenance-cal",
                    element: <ProtectedRoute element={<MaintenanceCalender />} />,
                },
            ],
        },
        {
            path: "/login",
            element: <GuestRoute element={<Login />} />,
        },
        {
            path: "/forgot-password",
            element: <GuestRoute element={<ForgetPassword />} />,
        },
        {
            path: "/reset-password",
            element: <GuestRoute element={<ResetPassword />} />,
        },
        {
            path: "/logout",
            element: <ProtectedRoute element={<Logout />} />,
        },
        {
            path: "/admin-login",
            element: <AdminLogin />,
        },
    ];
}

export const HolotrakApp = () => {
    const user: HolotrakUser = useTypedSelector(selectCurrentUser); // Get User from Redux Store
    const rootRouter = createBrowserRouter(getApplicationRoutes(user));

    return <RouterProvider router={rootRouter} />;
};
