import {EDeviceEventLevel, getDeviceEventLevel} from "contracts/EDeviceEventLevel";
import {getDeviceEventSubTypeLabel} from "contracts/EDeviceEventSubType";
import {
    EDeviceEventType
} from "contracts/EDeviceEventType";
import dayjs from "dayjs";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {EventData, EventDataSerializer} from "./eventData";
import {EventTags} from "./eventTags";
import {EventUpdate} from "./eventUpdate";

@jsonObject()
export class DeviceEvent {
    @jsonMember(String)
    subject: string;
    @jsonMember(String)
    message: string;

    @jsonMember(() => EventData, {
        deserializer: (value: any) => {
            try {
                return value && EventDataSerializer.parse(value);
            } catch (exception) {
                return new EventData();
            }
        }
    })
    data: EventData;

    @jsonMember(String)
    deviceId: string;

    @jsonMember(() => EventTags)
    eventTags: EventTags;

    @jsonMember(String)
    sourceType: string;
    @jsonMember(String)
    sourceId: string;
    @jsonMember(String)
    applicationId: string;
    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    creationDate: dayjs.Dayjs;
    @jsonMember(String)
    lastUpdated: string;
    @jsonArrayMember(EventUpdate)
    updates: EventUpdate[];
    @jsonMember(String)
    eventId: string;
    @jsonMember(String)
    id: string;

    @jsonMember(() => EDeviceEventLevel, {
        deserializer: (value: unknown) => {
            return value && getDeviceEventLevel(value as string);
        }
    })
    level: EDeviceEventLevel;

    @jsonMember(String)
    state: string;
    @jsonMember(String)
    deviceName: string;
    @jsonMember(String)
    sourceName: string;

    get messageText(): string {
        if (this.message) {
            return this.message;
        } else {
            switch (this.eventTags.type) {
                case EDeviceEventType.COMMAND:
                    return getDeviceEventSubTypeLabel(this.eventTags.sub_type);
                case EDeviceEventType.STATUS:
                default:
                    return 'Status Report';
            }
        }
    }
}

export const DeviceEventSerializer = new TypedJSON(DeviceEvent);
