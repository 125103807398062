import {losantApi} from "./index";
import {Trip} from "contracts/holotrak/trip";
import {ITripRequest} from "contracts/ITripRequest";
import {DeviceEvent} from "../../../contracts/holotrak/deviceEvent";

export const tripsApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceTrips: builder.query<Trip[], ITripRequest>({
            query: (tripRequest) => {
                return {
                    url: `/trips/${tripRequest.deviceId}?limit=${tripRequest.limit}&offset=${tripRequest.offset}&startDateRange=${tripRequest.startDateRange}&endDateRange=${tripRequest.endDateRange}`,
                    method: "get",
                };
            }
        }),
        getDeviceEvents: builder.query<DeviceEvent[], ITripRequest>({
            query: (tripRequest) => {
                let url = `/event-log/${tripRequest.deviceId}?limit=${tripRequest.limit}&offset=${tripRequest.offset}&startDateRange=${tripRequest.startDateRange}&endDateRange=${tripRequest.endDateRange}`;

                if (tripRequest.filter) {
                    url += `&filter=${tripRequest.filter}`;
                }

                return {
                    url,
                    method: "get",
                };
            }
        })
    })
});

export const {
    useGetDeviceTripsQuery,
    useGetDeviceEventsQuery
} = tripsApi;
