export enum EDeviceEventSubType {
    GENERAL = 'general',
    ALARM_ARMED = 'alarm_armed',
    ALARM_DISARMED = 'alarm_disarmed',
    SIREN_ON = 'siren_on',
    SIREN_OFF = 'siren_off',
    KILL_ENGINE_ON = 'kill_engine_on',
    KILL_ENGINE_OFF = 'kill_engine_off',
    RESET = 'reset',
    LOCATE = 'locate',
}

export function getDeviceEventSubTypeLabel(subType: EDeviceEventSubType): string {
    switch (subType) {
        case EDeviceEventSubType.SIREN_ON:
            return 'Siren was Turned On.';
        case EDeviceEventSubType.SIREN_OFF:
            return 'Siren was Turned Off.';
        case EDeviceEventSubType.ALARM_ARMED:
            return 'Alarm was Armed.';
        case EDeviceEventSubType.ALARM_DISARMED:
            return 'Alarm was Disarmed.';
        case EDeviceEventSubType.KILL_ENGINE_ON:
            return 'Kill Engine was Turned On.';
        case EDeviceEventSubType.KILL_ENGINE_OFF:
            return 'Kill Engine was Turned Off.';
        case EDeviceEventSubType.RESET:
            return 'Device Reset command was sent.';
        case EDeviceEventSubType.LOCATE:
            return 'Device was polled.';
        case EDeviceEventSubType.GENERAL:
        default:
            return 'General Command';

    }
}

export function getDeviceEventSubType(subType: string): EDeviceEventSubType {
    switch (subType?.toLowerCase()) {
        case 'alarm_armed':
            return EDeviceEventSubType.ALARM_ARMED;
        case 'alarm_disarmed':
            return EDeviceEventSubType.ALARM_DISARMED;
        case 'siren_on':
            return EDeviceEventSubType.SIREN_ON;
        case 'siren_off':
            return EDeviceEventSubType.SIREN_OFF;
        case 'kill_engine_on':
            return EDeviceEventSubType.KILL_ENGINE_ON;
        case 'kill_engine_off':
            return EDeviceEventSubType.KILL_ENGINE_OFF;
        case 'reset':
            return EDeviceEventSubType.RESET;
        case 'locate':
            return EDeviceEventSubType.LOCATE;
        case 'general':
        default:
            return EDeviceEventSubType.GENERAL;
    }
}
