import {ISensorSaveRequest, SensorBase} from "contracts/holotrak/sensorBase";
import {ProductConfigResponse} from "contracts/ProductConfigResponse";
import {
    TAG_DEVICE_SENSOR,
    TAG_DEVICE_SENSOR_LINK,
    TAG_DEVICE_SENSOR_REPORT,
    TAG_PRODUCT_CONFIGS
} from "../../../contracts/reduxResourceTags";
import {losantApi} from "./index";

const sensorsApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({

        updateSensor: builder.mutation<SensorBase, ISensorSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [
                    {
                        type: TAG_DEVICE_SENSOR,
                        id: "LIST",
                    },
                    {
                        type: TAG_DEVICE_SENSOR_REPORT,
                        id: "LIST",
                    },
                    {
                        type: TAG_DEVICE_SENSOR,
                        id: result.id,
                    },
                    {
                        type: TAG_DEVICE_SENSOR_LINK,
                        id: result.deviceId
                    }
                ];
            },
            query: (sensorBody) => {
                let sensorData = {
                    ...sensorBody.data,
                };

                return {
                    url: `/device-sensors/${sensorBody.data.id}`,
                    method: "put",
                    body: {
                        data: sensorData
                    },
                };
            },
        }),

        createSensor: builder.mutation<SensorBase, ISensorSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [
                    {
                        type: TAG_DEVICE_SENSOR,
                        id: "LIST",
                    },
                    {
                        type: TAG_DEVICE_SENSOR_REPORT,
                        id: "LIST",
                    },
                    {
                        type: TAG_DEVICE_SENSOR,
                        id: result.id,
                    },
                    {
                        type: TAG_DEVICE_SENSOR_LINK,
                        id: result.deviceId
                    }
                ];
            },
            query: (sensorBody) => {
                return {
                    url: `/device-sensors`,
                    method: "post",
                    body: {
                        data: {
                            ...sensorBody.data,
                        }
                    },
                };
            },
        }),


        deleteSensor: builder.mutation<void, string>({
            invalidatesTags: (result, error, sensorId) => {
                return [
                    {
                        type: TAG_DEVICE_SENSOR_LINK,
                    },
                    {
                        type: TAG_DEVICE_SENSOR_REPORT,
                        id: sensorId,
                    },
                    {
                        type: TAG_DEVICE_SENSOR,
                        id: "LIST",
                    },
                    {
                        type: TAG_DEVICE_SENSOR,
                        id: sensorId,
                    }
                ];
            },
            query: (sensorId) => {
                return {
                    url: `/device-sensors/${sensorId}`,
                    method: "delete",
                };
            }
        }),

        fetchProductConfigs: builder.query<ProductConfigResponse, void>({
            query: () => ({
                url: `/admin/product-configs`,
                method: "get",
            }),
            providesTags: (result) => [{
                type: TAG_PRODUCT_CONFIGS,
                id: "LIST",
            }]
        }),

    })
});


export const {
    useCreateSensorMutation,
    useDeleteSensorMutation,
    useUpdateSensorMutation,
    useFetchProductConfigsQuery
} = sensorsApi;
