import {IReportableItem} from "contracts/IReportableItem";
import React from "react";
import { Button, Form, Input, message } from "antd";
import { useUpdateDeviceTagMutation } from "redux/services/losant/devices";
import { useSyncDevicesMutation } from "redux/services/express";
import { Select, DatePicker, Row, Col } from "antd";

const { Option } = Select;

export const AddSchedule = (props) => {
    const [form] = Form.useForm();

    const [updateDeviceDetails, { isLoading: isUpdatingDetails }] = useUpdateDeviceTagMutation();

    const [syncDevices, { isLoading: isSyncingItems }] = useSyncDevicesMutation();

    const saveDetails = () => {
        form.validateFields().then((values) => {
            updateDeviceDetails({
                deviceId: props.item.id,
                tag: values.tag as string,
                value: values.layout as string,
            })
                .then(() => {
                    return syncDevices([props.item as IReportableItem]);
                })
                .then(() => {
                    props.onSave(props.item);
                    return message.info("Updated successfully");
                });
        });
    };
    const onFinish = (values) => {
        console.log("Form values:", values);
    };

    const handleCancel = () => {
        form.resetFields();
        props.onCancel();
    };
    return (
        <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
                asset: "",
                vehicle: "",
                distance: "",
                time: "",
                date: null,
                task: "",
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Add Asset" />
                </Col>
                <Col span={24}>
                    <Form.Item label="Asset" name="asset" rules={[{ message: "Please select an asset!" }]}>
                        <Select placeholder="Select Asset">
                            <Option value="asset1">Asset 1</Option>
                            <Option value="asset2">Asset 2</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Vehicle" name="vehicle" rules={[{ message: "Please select a vehicle!" }]}>
                        <Select placeholder="Select Vehicle">
                            <Option value="">Select Vehicle</Option>
                            <Option value="vehicle1">Vehicle 1</Option>
                            <Option value="vehicle2">Vehicle 2</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Distance" name="distance" rules={[{ message: "Please input the distance!" }]}>
                        <Input placeholder="Add Distance" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Time" name="time" rules={[{ message: "Please input the time!" }]}>
                        <Input placeholder="Add Time" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Add Asset" />
                </Col>
                <Col span={24}>
                    <Form.Item label="Date" name="date" rules={[{ message: "Please select a date!" }]}>
                        <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Distance" name="distance2" rules={[{ message: "Please input the distance!" }]}>
                        <Input placeholder="Add Distance" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Time" name="time2" rules={[{ message: "Please input the time!" }]}>
                        <Input placeholder="Add Time" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Service" />
                </Col>
                <Col span={24}>
                    <Form.Item label="Task" name="task" rules={[{ message: "Please select a task!" }]}>
                        <Select placeholder="Select Task">
                            <Option value="">Select Task</Option>
                            <Option value="task1">Task 1</Option>
                            <Option value="task2">Task 2</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Send Alert" />
                </Col>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Col span={8}>
                        <Select placeholder="Select Days">
                            <Option value="7">7 Days</Option>
                            <Option value="14">14 Days</Option>
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select placeholder="Select Miles">
                            <Option value="100">100 Miles</Option>
                            <Option value="200">200 Miles</Option>
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select placeholder="Select Hours">
                            <Option value="20">20 Hours</Option>
                            <Option value="40">40 Hours</Option>
                        </Select>
                    </Col>
                </div>

                <Col span={24} style={{ marginTop: "20px" }}>
                    <Form.Item label="Notifications" />
                </Col>
                <Col span={24}>
                    <Form.Item label="Phone Number" name="phone">
                        <Input placeholder="Enter Phone Number" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Email" name="email">
                        <Input placeholder="Enter Email" />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="end" gutter={24} style={{ gap: "20px" }}>
                <Col>
                    <Button htmlType="submit" style={{ color: "black" }}>
                        Schedule
                    </Button>
                </Col>

                <Col style={{ padding: "0px" }}>
                    <Button onClick={handleCancel} style={{ color: "black" }}>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
