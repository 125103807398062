import {CompositeStateType} from "contracts/holotrak/compositeState";

export function getCompositeItemSuffix(index: CompositeStateType): string {
    switch (index) {
        case "fuel":
            return "%";
        case "coolant":
            return "%";
        case "rpm":
            return "RPMs";
        case "speed":
            return "MPH";
        case "coolant_temperature":
            return "F";
        case "throttle":
            return "%";
        case "absolute_load_value":
            return "%";
        case "engine_start_run_time":
            return "S";
        case "distance_mil_on":
            return "km";
        case "voltage_level":
            return "V";
        case "errors":
            return
        case "engine_oil_maintenance_hours":
            return "hrs.";
        case "brush_maintenance_hours":
            return "hrs.";
        case "battery_terminal_maintenance_hours":
            return "hrs.";
        case "air_cleaner_maintenance_hours":
            return "hrs.";
        case "spark_plug_maintenance_hours":
            return "hrs.";
        case "fuel_filter_maintenance_hours":
            return "hrs.";
        case "ac_run_hours":
            return "hrs.";
        case "ac_oil_maintenance_hours":
            return "hrs.";
        case "hydraulics_oil_temp":
            return "F";
        case "hydraulics_usage":
            return "hrs.";
        case "aux_usage":
            return "hrs.";
        case "weld_usage":
            return "hrs.";
        case "idle_time":
            return "hrs.";
        case "machine_stick_pu":
            return "hrs.";
        case "machine_tig_pu":
            return "hrs.";
        case "machine_gauge_pu":
            return "hrs.";
        case "machine_spool_gun_pu":
            return "hrs.";
        case "machine_feeder_pu":
            return "hrs.";
        case "auto_start_stop_uc":
            return
        case "pin_usage":
            return "hrs.";
        case "battery_level":
            return "%";
        case "temperature":
            return "F";
        case "altitude":
            return "m";

        default:
            return '';
    }
}

// TODO: Find a way Remove alternative titles.
export function getCompositeItemTitle(index: CompositeStateType, alternateTitleSet: boolean = false): string {
    switch (index) {
        case 'temperature':
            return "Temperature";
        case 'fill_level':
            return "Fill Level";
        case 'tilt':
            return "Tilt";
        case 'altitude':
            return "Altitude";
        case 'battery_level':
            return "Battery";
        case 'humidity':
            // TODO: Find a dedicated state item for pressure.
            return alternateTitleSet ? "Pressure" : "Humidity";
        case 'is_door_open':
            return "Door Open";
        case 'engine_oil_maintenance_hours':
            return 'Engine Oil Maintenance Hours';
        case 'voltage_level':
            return alternateTitleSet ? "Unit Battery terminal voltage" : 'Voltage';
        case 'errors':
            return "Device Weld module errors";
        case 'engine_start_run_time':
            return "Engine Hours";
        case 'rpm':
            return 'Engine Speed';
        case "brush_maintenance_hours":
            return "Generator Brush block maintenance hours";
        case "battery_terminal_maintenance_hours":
            return "Battery Terminal Maintenance Hours";
        case "air_cleaner_maintenance_hours":
            return "Air Cleaner Maintenance Hours";
        case "spark_plug_maintenance_hours":
            return "Spark Plug Maintenance Hours";
        case "fuel_filter_maintenance_hours":
            return "Fuel Filter Maintenance Hours";
        case "ac_run_hours":
            return "Air Compressor Total Run Hours";
        case "ac_oil_maintenance_hours":
            return "Air Compressor Oil Maintenance Hours Run Hours";
        case 'ac_max_psi_setting':
            return "Air Compressor Maximum PSI Setting";
        case 'ac_min_psi_setting':
            return "Air Compressor Minimum PSI Setting";
        case 'hydraulics_max_psi':
            return "Hydraulics Max PSI";
        case 'hydraulics_max_flow':
            return "Hydraulics Max Flow";
        case 'hydraulics_feedback_flow':
            return "Hydraulics Feedback Flow";
        case 'hydraulics_oil_temp':
            return "Hydraulics Oil Temperature";
        case 'hydraulics_usage':
            return "Hydraulics Usage Over Life time";
        case 'aux_usage':
            return "Machine Aux Power Usage in terms of hours";
        case 'weld_usage':
            return "Machine Weld Power Usage in terms of hours";
        case 'idle_time':
            return "Machine Idle time over Lifetime";
        case 'machine_stick_pu':
            return "Machine Stick Process Usage Over Life Time";
        case 'machine_tig_pu':
            return "Machine Tig Process Usage Over Life Time";
        case 'machine_gauge_pu':
            return "Machine Gauge Process Usage Over Life Time";
        case 'machine_spool_gun_pu':
            return "Machine Spool Gun Process Usage Over Life Time";
        case 'machine_feeder_pu':
            return "Machine Feeder Process Usage Over Life Time";
        case 'auto_start_stop_uc':
            return "Auto Start Stop Usage Count";
        case 'pin_usage':
            return "14 PIN Usage Over Life Time";
        case 'battery_charge_12v':
            return "12V Battery Charge Usage Life Time Count";
        case 'battery_charge_24v':
            return "24V Battery Charge Usage Life Time Count";
        case 'machine_stick_process_avgc':
            return "Machine Stick Process Average Current";
        case 'machine_tig_process_avgc':
            return "Machine Tig Process Average Current";
        case 'machine_gauge_process_avgc':
            return "Machine Gauge Process Average Current";
        case 'machine_spool_gun_avgc':
            return "Machine Spool Gun Average Current";
        case 'machine_feeder_avgc':
            return "Machine Feeder Average Current";
        case 'cb_apploader_pn':
            return "Control Board AppLoader Partnumber";
        case 'cb_application_pn':
            return "Control Board Application Partnumber";
        case 'uib_application_pn':
            return "UI Board Application Partnumber";
        case 'uib_apploader_pn':
            return "UI Board Apploader Partnumber";
        case 'uib_applauncher_pn':
            return "UI Board Applauncher Partnumber";
        case 'cb_applauncher_pn':
            return "Control Board AppLauncher Partnumber";
        case 'cb_application_sr':
            return "Control Board Application Software Revision";
        case 'cb_applauncher_sr':
            return "Control Board AppLauncher Software Revision";
        case 'cb_apploader_sr':
            return "Control Board AppLoader Software Revision";
        case 'ui_application_sr':
            return "UI Application Software Revision";
        case 'ui_applauncher_sr':
            return "UI AppLauncher Software Revision";
        case 'ui_apploader_sr':
            return "UI AppLoader Software Revision";
        case 'sys_product_class':
            return "System Product Class";
        case 'sys_product_config':
            return "System Product Configuration";
        case 'archreach_bind_attempts':
            return "ArcReach Bind Attempts";
        default:
            return index as string;
    }
}
