import {EDeviceEventSubType, getDeviceEventSubType} from "contracts/EDeviceEventSubType";
import {
    EDeviceEventType,
    getDeviceEventType
} from "contracts/EDeviceEventType";
import {jsonMember, jsonObject} from "typedjson";

@jsonObject()
export class EventTags {
    @jsonMember(String)
    organisation: string;

    @jsonMember(() => EDeviceEventType, {
        deserializer: (value: unknown) => {
            return getDeviceEventType(value as string);
        }
    })
    type: string;

    @jsonMember(() => EDeviceEventSubType, {
        deserializer: (value: unknown) => {
            return getDeviceEventSubType(value as string);
        }
    })
    sub_type: EDeviceEventSubType;
}
