export enum EDeviceStatus {
    ALL = 'ALL',
    ONLINE = 'Online',
    OFFLINE = 'Offline',
    GPS_NOT_CONNECTED = 'GPS Not Connected',
    MOVING = 'Moving',
    STOPPED = 'Stopped',
    IDLE = 'Idle',
    STOPPED_REPORTING = 'Stopped Reporting',
    NEVER_CONNECTED = 'Never Connected',

    ALARM_ARMED = 'Armed',
    ALARM_DISARMED = 'Disarmed',
}

export enum EDeviceSignal {
    EXCELLENT = 'Excellent',
    GOOD = 'Good',
    POOR = 'Poor',
    BAD = 'Bad',
    NONE = 'None',
}

export enum ETripStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export enum ELosantDeviceClass {
    GATEWAY = 'Gateway',
    FLOATING = 'Floating',
    DEVICE = 'Device',
}

export enum ESensorType {
    UNKNOWN = 'unknown',
    EXTERNAL_SENSOR = 'external_sensor',
    DEVICE = 'device',
    ALARMS = 'alarms',
    FILL_SENSOR_ONE = 'fill_sensor_one',
    VEHICLE = 'vehicle',
    MILLER_CAN = 'miller_can',
    INDOOR_BLUE_ANCHOR = 'indoor_blue_anchor',
    INDOOR_BLUE_TAG = 'indoor_blue_tag',
}

export function getSensorTypeFromString(value: string): ESensorType {
    switch (`${value}`.toLowerCase().trim()) {
        case 'device':
            return ESensorType.DEVICE;

        case 'alarms':
            return ESensorType.ALARMS;

        case 'miller_can':
            return ESensorType.MILLER_CAN;

        case 'indoor_blue_anchor':
            return ESensorType.INDOOR_BLUE_ANCHOR;

        case 'indoor_blue_tag':
            return ESensorType.INDOOR_BLUE_TAG;

        case 'external_sensor':
            return ESensorType.EXTERNAL_SENSOR;

        case 'fill_sensor':
        case 'fill_sensor_one':
            return ESensorType.FILL_SENSOR_ONE;

        case 'vehicle':
            return ESensorType.VEHICLE;
        default:
            return ESensorType.UNKNOWN;
    }
}

export function getDeviceStatusFromString(value: string): EDeviceStatus {
    switch (value.toLowerCase().trim()) {
        case 'armed':
            return EDeviceStatus.ALARM_ARMED;
        case 'disarmed':
            return EDeviceStatus.ALARM_DISARMED;
        case 'online':
            return EDeviceStatus.ONLINE;
        case 'offline':
            return EDeviceStatus.OFFLINE;
        case 'gps not connected':
            return EDeviceStatus.GPS_NOT_CONNECTED;
        case 'moving':
            return EDeviceStatus.MOVING;
        case 'stopped':
            return EDeviceStatus.STOPPED;
        case 'idle':
            return EDeviceStatus.IDLE;
        case 'stopped reporting':
            return EDeviceStatus.STOPPED_REPORTING;
        case 'never connected':
        default:
            return EDeviceStatus.NEVER_CONNECTED;
    }
}
