import { Middleware } from "@reduxjs/toolkit";
import { logout } from "./slices/auth"; // Import the logout action

const authenticationMiddleware: Middleware = ({ dispatch }) => (next) => (action) => {
    if (action.type.endsWith('/rejected') && action.payload?.status === 401) {
        dispatch(logout());
        window.location.reload();
    }
    return next(action);
};

export default authenticationMiddleware;
