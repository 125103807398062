import {CompositeStateType} from "contracts/holotrak/compositeState";
import dayjs from "dayjs";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";


@jsonObject
export class CompositeStateItem {
    // Constructor
    constructor(value?: string, time?: dayjs.Dayjs) {
        this.value = value ?? '';
        this.time = time;
    }

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            if (value) {
                return dayjs(value);
            } else {
                return null;
            }
        }
    })
    time: dayjs.Dayjs;

    @jsonMember(String, {
        deserializer: (value: any) => {
            // TODO: This is a hack to get around the fact that the API returns a string for some values and a number for others.
            if (typeof value === 'string') {
                return value;
            } else if (typeof value === 'number') {
                return value.toString();
            } else {
                return value ? 'Y' : '';
            }
        }
    })
    value: string;

    get updatedAt(): dayjs.Dayjs {
        return this.time;
    }

    static getFiveMinutesInSeconds() {
        return 5 * 60;
    }

    static getFifteenDaysInSeconds() {
        return 15 * 24 * 60 * 60;
    }

    secondsElapsed() {
        return this.time ? dayjs().diff(this.time, 'second') : null;
    }

    hoursElapsed() {
        return this.time ? dayjs().diff(this.time, 'hour') : null;
    }

    humanDifference() {
        return this.time ? dayjs(this.time).fromNow() : null;
    }

    hasValue(): boolean {
        return this.value !== undefined && !!this.value;
    }

    hasValidMillerValue(type: CompositeStateType): boolean {
        switch (type) {
            case 'fuel':
            case 'errors':
            case "archreach_bind_attempts":
            case "auto_start_stop_uc":
                return this.getIntValue() !== 255;

            case 'voltage_level':
            case "engine_start_run_time":
            case "engine_oil_maintenance_hours":
            case "brush_maintenance_hours":
            case "battery_terminal_maintenance_hours":
            case "air_cleaner_maintenance_hours":
            case "spark_plug_maintenance_hours":
            case "fuel_filter_maintenance_hours":
            case "ac_run_hours":
            case "ac_oil_maintenance_hours":
            case "ac_min_psi_setting":
            case "ac_max_psi_setting":
            case "hydraulics_max_psi":
            case "hydraulics_max_flow":
            case "hydraulics_feedback_flow":
            case "hydraulics_oil_temp":
            case "hydraulics_usage":
            case "aux_usage":
            case "weld_usage":
            case "idle_time":
            case "machine_stick_pu":
            case "machine_tig_pu":
            case "machine_gauge_pu":
            case "machine_spool_gun_pu":
            case "machine_feeder_pu":
            case "pin_usage":
            case "battery_charge_12v":
            case "battery_charge_24v":
            case "machine_stick_process_avgc":
            case "machine_tig_process_avgc":
            case "machine_gauge_process_avgc":
            case "machine_spool_gun_avgc":
            case "machine_feeder_avgc":
            case "ui_application_sr":
            case "ui_applauncher_sr":
            case "ui_apploader_sr":
            case "sys_product_class":
            case "sys_product_config":
                return this.getIntValue() !== 65535;

            case "cb_apploader_pn":
            case "cb_application_pn":
            case "uib_application_pn":
            case "uib_apploader_pn":
            case "uib_applauncher_pn":
            case "cb_applauncher_pn":
            case "cb_application_sr":
            case "cb_applauncher_sr":
            case "cb_apploader_sr":
                return this.getIntValue() !== 4294967295;

            default:
                return true;
        }
    }

    getIntValue(defaultValue = 0): number {
        return (this.hasValue()) ? parseInt(this.value, 10) : defaultValue;
    }

    getFixedValue(precision = 2, defaultValue: number | undefined = 0): string {
        return (this.hasValue()) ? parseFloat(this.value).toFixed(precision) : defaultValue?.toFixed(precision);
    }

    toString(): string {
        return this.value;
    }

    getFiniteValue(): String | Number | Boolean | null {
        if (this.value === 'NaN') {
            return null;
        }
        if (this.value === 'Infinity') {
            return null;
        }
        if (this.value === '-Infinity') {
            return null;
        }
        return this.value;
    }
}

@jsonObject
export class DateCompositeStateItem extends CompositeStateItem {
    toString(): string {
        return this.humanDifference();
    }
}

@jsonObject
export class FloatCompositeStateItem extends CompositeStateItem {
    toString(): string {
        return this.getFiniteValue() as string;
    }

    getFiniteValue() {
        return this.getFixedValue(2);
    }
}

@jsonObject
export class IntCompositeStateItem extends CompositeStateItem {
    toString(): string {
        return this.getFiniteValue().toString();
    }

    getFiniteValue() {
        return this.getIntValue();
    }
}

@jsonObject
export class BooleanCompositeStateItem extends CompositeStateItem {
    toString(): string {
        return this.getFiniteValue() ? 'Yes' : 'No';
    }

    getFiniteValue() {
        return !!this.value && (this.value === 'Y' || this.value === '1' || this.value === 'true');
    }
}

export const CompositeStateItemSerializer = new TypedJSON(CompositeStateItem);
export const FloatCompositeSateItemSerializer = new TypedJSON(FloatCompositeStateItem);
export const BooleanCompositeStateItemSerializer = new TypedJSON(BooleanCompositeStateItem);
export const DateCompositeStateItemSerializer = new TypedJSON(DateCompositeStateItem);
export const IntCompositeStateItemSerializer = new TypedJSON(IntCompositeStateItem);

export const CompositeStateItemSerializers = {
    status: CompositeStateItemSerializer,
    region_id: CompositeStateItemSerializer,

    position_x: FloatCompositeSateItemSerializer,
    position_y: FloatCompositeSateItemSerializer,
    position_z: FloatCompositeSateItemSerializer,

    ac_run_hours: CompositeStateItemSerializer,
    ac_oil_maintenance_hours: CompositeStateItemSerializer,
    ac_min_psi_setting: CompositeStateItemSerializer,
    ac_max_psi_setting: CompositeStateItemSerializer,
    hydraulics_max_psi: CompositeStateItemSerializer,
    hydraulics_max_flow: CompositeStateItemSerializer,
    hydraulics_feedback_flow: CompositeStateItemSerializer,
    hydraulics_oil_temp: CompositeStateItemSerializer,
    hydraulics_usage: CompositeStateItemSerializer,
    machine_stick_pu: CompositeStateItemSerializer,
    machine_tig_pu: CompositeStateItemSerializer,
    machine_gauge_pu: CompositeStateItemSerializer,
    machine_spool_gun_pu: CompositeStateItemSerializer,
    machine_feeder_pu: CompositeStateItemSerializer,
    auto_start_stop_uc: CompositeStateItemSerializer,
    pin_usage: CompositeStateItemSerializer,
    battery_charge_12v: CompositeStateItemSerializer,
    battery_charge_24v: CompositeStateItemSerializer,
    machine_stick_process_avgc: CompositeStateItemSerializer,
    machine_tig_process_avgc: CompositeStateItemSerializer,
    machine_gauge_process_avgc: CompositeStateItemSerializer,
    machine_spool_gun_avgc: CompositeStateItemSerializer,
    machine_feeder_avgc: CompositeStateItemSerializer,
    cb_apploader_pn: CompositeStateItemSerializer,
    cb_application_pn: CompositeStateItemSerializer,
    uib_application_pn: CompositeStateItemSerializer,
    uib_apploader_pn: CompositeStateItemSerializer,
    uib_applauncher_pn: CompositeStateItemSerializer,
    cb_applauncher_pn: CompositeStateItemSerializer,
    cb_application_sr: CompositeStateItemSerializer,
    cb_applauncher_sr: CompositeStateItemSerializer,
    cb_apploader_sr: CompositeStateItemSerializer,
    ui_application_sr: CompositeStateItemSerializer,
    ui_applauncher_sr: CompositeStateItemSerializer,
    ui_apploader_sr: CompositeStateItemSerializer,
    sys_product_class: CompositeStateItemSerializer,
    sys_product_config: CompositeStateItemSerializer,
    archreach_bind_attempts: CompositeStateItemSerializer,
    engine_oil_maintenance_hours: CompositeStateItemSerializer,
    brush_maintenance_hours: CompositeStateItemSerializer,
    battery_terminal_maintenance_hours: CompositeStateItemSerializer,
    air_cleaner_maintenance_hours: CompositeStateItemSerializer,
    spark_plug_maintenance_hours: CompositeStateItemSerializer,
    fuel_filter_maintenance_hours: CompositeStateItemSerializer,

    aux_usage: CompositeStateItemSerializer,
    weld_usage: CompositeStateItemSerializer,
    idle_time: CompositeStateItemSerializer,

    address: CompositeStateItemSerializer,

    location: CompositeStateItemSerializer,
    direction: IntCompositeStateItemSerializer,

    battery_level: FloatCompositeSateItemSerializer,
    voltage_level: FloatCompositeSateItemSerializer,
    last_reported_at: DateCompositeStateItemSerializer,
    last_location_updated_at: DateCompositeStateItemSerializer,

    fill_level: FloatCompositeSateItemSerializer,

    altitude: FloatCompositeSateItemSerializer,
    hdop: CompositeStateItemSerializer,
    tilt: BooleanCompositeStateItemSerializer,

    ignition: BooleanCompositeStateItemSerializer,

    speed: IntCompositeStateItemSerializer,
    rpm: IntCompositeStateItemSerializer,
    throttle: FloatCompositeSateItemSerializer,


    carrier: CompositeStateItemSerializer,
    comm_state: CompositeStateItemSerializer,
    satellites: CompositeStateItemSerializer,
    signal_strength: IntCompositeStateItemSerializer,

    trip_time: CompositeStateItemSerializer,
    trip_distance: CompositeStateItemSerializer,


    temperature: FloatCompositeSateItemSerializer,
    humidity: FloatCompositeSateItemSerializer,
    coolant_temperature: IntCompositeStateItemSerializer,
    coolant: CompositeStateItemSerializer,
    fuel: FloatCompositeSateItemSerializer,


    absolute_load_value: FloatCompositeSateItemSerializer,
    engine_start_run_time: FloatCompositeSateItemSerializer,
    distance_mil_on: CompositeStateItemSerializer,

    vibration: CompositeStateItemSerializer,
    external_power_cut: CompositeStateItemSerializer,

    eventCode: IntCompositeStateItemSerializer,
    errors: CompositeStateItemSerializer,

    input_1: BooleanCompositeStateItemSerializer,
    input_2: BooleanCompositeStateItemSerializer,
    input_3: BooleanCompositeStateItemSerializer,
    input_4: BooleanCompositeStateItemSerializer,
    input_5: BooleanCompositeStateItemSerializer,
    input_6: BooleanCompositeStateItemSerializer,
    input_7: BooleanCompositeStateItemSerializer,

    output_1: BooleanCompositeStateItemSerializer,
    output_2: BooleanCompositeStateItemSerializer,

    is_door_open: BooleanCompositeStateItemSerializer,
    is_alarm_armed: BooleanCompositeStateItemSerializer,
    is_alarm_triggered: BooleanCompositeStateItemSerializer,
}
