import {Space, Typography} from "antd";
import Tooltip from "antd/es/tooltip";
import React, {useMemo} from 'react';
import {HolotrakUserSerializer} from "../../contracts/holotrak/holotrakUser";
import {selectCurrentUser} from "../../redux/slices/auth";
import {useTypedSelector} from "../../redux/store";
import {UserAvatar} from "./UserAvatar";

const {Text} = Typography;

interface IUserProfileProps {
    isCollapsed?: boolean;
}

export const UserProfile: React.FC<IUserProfileProps> = ({isCollapsed}) => {
    const userInState = useTypedSelector(selectCurrentUser);
    const user = useMemo(() => HolotrakUserSerializer.parse(userInState), [userInState]);

    const {fullName, trimmed} = useMemo(() => {
        if (!user) return {fullName: '', trimmed: false};

        const limit = 7;
        let trimmed = false;
        let fullName = user.fullName;
        if (fullName.length > limit) {
            fullName = `${fullName.slice(0, limit)}...`;
            trimmed = true;
        }

        return {
            trimmed,
            fullName,
        };
    }, [user]);

    return (
        <Tooltip
            placement={'right'}
            overlayClassName='holotrak-user-name'
            title={user?.fullName ?? ''}
            color={'#fff'}

        >
            <Space
                className={`holotrak-user ${isCollapsed ? 'collapsed' : ''}`}
                size={8}
            >
                <UserAvatar user={user}/>
                {
                    !isCollapsed && (trimmed ? (
                        <Text>{fullName}</Text>
                    ) : (
                        <Text>{fullName}</Text>
                    ))
                }
            </Space>
        </Tooltip>
    );
}
