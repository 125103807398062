import {IReportableItem} from "contracts/IReportableItem";
import React from "react";
import { Button, Form, Input, InputNumber, message } from "antd";
import { useUpdateDeviceTagMutation } from "redux/services/losant/devices";
import { useSyncDevicesMutation } from "redux/services/express";
import { Select, DatePicker, Row, Col } from "antd";

const { Option } = Select;

export const AddMaintenanceLog = (props) => {
    const [form] = Form.useForm();

    const [updateDeviceDetails, { isLoading: isUpdatingDetails }] = useUpdateDeviceTagMutation();

    const [syncDevices, { isLoading: isSyncingItems }] = useSyncDevicesMutation();

    const saveDetails = () => {
        form.validateFields().then((values) => {
            updateDeviceDetails({
                deviceId: props.item.id,
                tag: values.tag as string,
                value: values.layout as string,
            })
                .then(() => {
                    return syncDevices([props.item as IReportableItem]);
                })
                .then(() => {
                    props.onSave(props.item);
                    return message.info("Updated successfully");
                });
        });
    };
    const onFinish = (values) => {
        console.log("Form values:", values);
        // onClose(); // Close modal after form submission
    };

    const handleCancel = () => {
        form.resetFields(); // Reset form fields on cancel
        props.onCancel(); // Optionally, trigger any cancel behavior
    };
    return (
        <Form
            layout="horizontal"
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
                assets: "",
                servicingDate: null,
                depot: "",
                task: "",
                odometer: 100,
                travelTime: 20,
                cost: "",
                loggedBy: "",
            }}
        >
            {/* Assets Select */}
            <Form.Item label="Assets" name="assets" rules={[{ message: "Please select assets!" }]}>
                <Select placeholder="Select Assets">
                    <Option value="">Select Assets</Option>
                    <Option value="asset1">Asset 1</Option>
                    <Option value="asset2">Asset 2</Option>
                </Select>
            </Form.Item>

            {/* Servicing Date */}
            <Form.Item label="Servicing Date" name="servicingDate" rules={[{ message: "Please select a date!" }]}>
                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
            </Form.Item>

            {/* Service Depot */}
            <Form.Item label="Service Depot" name="depot" rules={[{ message: "Please select a depot!" }]}>
                <Select placeholder="Select Depot">
                    <Option value="">Select Depot</Option>
                    <Option value="depot1">Depot 1</Option>
                    <Option value="depot2">Depot 2</Option>
                </Select>
            </Form.Item>

            {/* Task (Maintenance) */}

            <Form.Item label="Maintenance" />
            <Form.Item label="Task" name="task" rules={[{ message: "Please select a task!" }]}>
                <Select placeholder="Select Task">
                    <Option value="">select Task</Option>
                    <Option value="task1">Task 1</Option>
                    <Option value="task2">Task 2</Option>
                </Select>
            </Form.Item>

            {/* Current Odometer */}
            <Row gutter={16} style={{ marginBottom: "24px" }}>
                <Col span={12}>
                    <Form.Item
                        label="Current Odometer"
                        name="odometer"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ message: "Please input odometer value!" }]}
                        className="custom-form-item"
                    ></Form.Item>
                    <InputNumber
                        min={0}
                        formatter={(value) => `${value} Miles`}
                        // parser={(value) => value.replace(" Miles", "")}
                        style={{ width: "100%" }}
                    />
                </Col>

                {/* Total Travelled Time */}
                <Col span={12}>
                    <Form.Item
                        label="Total Travelled Time"
                        name="travelTime"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        rules={[{ message: "Please input travelled time!" }]}
                    ></Form.Item>
                    <InputNumber
                        min={0}
                        formatter={(value) => `${value} Hours`}
                        // parser={(value) => value.replace(" Hours", "")}
                        style={{ width: "100%" }}
                    />
                </Col>
            </Row>

            {/* Cost */}
            <Form.Item label="Cost" name="cost" rules={[{ message: "Please input cost!" }]}>
                <Input placeholder="Add Cost" />
            </Form.Item>

            {/* Logged By */}
            <Form.Item label="Logged By" name="loggedBy" rules={[{ message: "Please select a user!" }]}>
                <Select placeholder="Select User">
                    <Option value="">Select User</Option>
                    <Option value="user1">User 1</Option>
                    <Option value="user2">User 2</Option>
                </Select>
            </Form.Item>

            {/* Footer Buttons */}
            {/* <Form.Item> */}
            <Row justify="end" gutter={24} style={{ width: "100%", gap: "20px", margin: "0px" }}>
                <Col style={{ padding: "0px", gap: "10px" }}>
                    <Button htmlType="submit" style={{ color: "black" }}>
                        Save
                    </Button>
                </Col>
                <Col style={{ padding: "0px" }}>
                    <Button onClick={handleCancel} style={{ color: "black" }}>
                        Cancel
                    </Button>
                </Col>
            </Row>
            {/* </Form.Item> */}
        </Form>
    );
};
